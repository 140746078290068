import React from 'react'
import Footer from '../common/Footer'
import Navbar from '../common/Navbar'

function TermsAndPrivacyPolicy() {
  return (
    <>
      <Navbar />
      <div className="bg-black text-white py-24 space-y-10 px-[10%] text-justify">
        <h1 className="text-5xl text-center font-semibold">Terms of Use</h1>
        <div className="space-y-5">
          <p>
            Welcome to the Vape Monkey NFT website (the "Site"). By accessing or
            using the Site, you agree to be bound by the following terms and
            conditions (the "Terms of Use"). If you do not agree to the Terms of
            Use, please do not use the Site.
          </p>
          <p>
            The Site is owned and operated by Vape Monkey, LLC (the "Company").
            The Company reserves the right to modify the Terms of Use at any
            time, and such modifications shall be effective immediately upon
            posting on the Site. You agree to review the Terms of Use
            periodically to be aware of any such modifications and your
            continued use of the Site shall be deemed your conclusive acceptance
            of the modified Terms of Use.
          </p>
          <p>
            The Site is intended for users who are at least 18 years of age. By
            accessing or using the Site, you represent and warrant that you are
            at least 18 years of age and have the legal capacity to enter into a
            binding agreement.
          </p>
        </div>

        <h2 className="text-5xl text-center font-semibold">Use of the Site</h2>
        <div className="space-y-5">
          <p>
            The Site is for your personal, non-commercial use only. You may not
            use the Site for any purpose that is unlawful or prohibited by these
            Terms of Use. You may not use the Site in any manner that could
            damage, disable, overburden, or impair the Site or interfere with
            any other party's use of the Site. You may not obtain or attempt to
            obtain any materials or information through any means not
            intentionally made available or provided for through the Site.
          </p>
        </div>

        <h2 className="text-5xl text-center font-semibold">
          Intellectual Property
        </h2>
        <div className="space-y-5">
          <p>
            The content of the Site, including but not limited to text,
            graphics, images, and software, is the property of the Company or
            its licensors and is protected by United States and international
            copyright and trademark laws. The compilation of all content on the
            Site is the exclusive property of the Company and is protected by
            United States and international copyright laws.
          </p>
          <p>
            You may not use any content on the Site for any commercial purpose
            without the express written consent of the Company. You may not
            modify, reproduce, distribute, display, or create derivative works
            from any content on the Site without the express written consent of
            the Company.
          </p>
        </div>

        <h2 className="text-5xl text-center font-semibold">Privacy Policy</h2>
        <div className="space-y-5">
          <p>
            The Company is committed to protecting your privacy. Please review
            our Privacy Policy, which explains how we collect, use, and share
            information about you when you use the Site.
          </p>
        </div>

        <h2 className="text-5xl text-center font-semibold">
          Limitation of Liability
        </h2>
        <div className="space-y-5">
          <p>
            The Company shall not be liable for any damages of any kind arising
            from the use of the Site, including but not limited to direct,
            indirect, incidental, punitive, and consequential damages.
          </p>
        </div>

        <h2 className="text-5xl text-center font-semibold">Governing Law</h2>
        <div className="space-y-5">
          <p>
            These Terms of Use and your use of the Site shall be governed by and
            construed in accordance with the laws of the State of [State],
            without giving effect to any principles of conflicts of law.
          </p>
        </div>

        <h2 className="text-5xl text-center font-semibold">Severability</h2>
        <div className="space-y-5">
          <p>
            If any provision of these Terms of Use is found to be invalid or
            unenforceable, that provision shall be enforced to the maximum
            extent possible, and the remaining provisions shall remain in full
            force and effect.
          </p>
        </div>

        <h2 className="text-5xl text-center font-semibold">Entire Agreement</h2>
        <div className="space-y-5">
          <p>
            These Terms of Use constitute the entire agreement between you and
            the Company and govern your use of the Site, superseding any prior
            agreements between you and the Company.
          </p>
        </div>

        <h2 className="text-5xl text-center font-semibold">Contact Us</h2>
        <div className="space-y-5">
          <p>
            If you have any questions about the Site or these Terms of Use,
            please contact us at [contact email].
          </p>
        </div>

        <h2 className="text-5xl text-center font-semibold">Privacy Policy</h2>
        <div className="space-y-5">
          <p>
            This Privacy Policy explains how Vape Monkey, LLC ("we," "us," or
            "our") collects, uses, and shares information about you when you use
            our website, www.vapemonkey.com (the "Site"). By using the Site
          </p>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default TermsAndPrivacyPolicy
