import React from 'react'

function Screen3() {
  return (
    <div className="screen3  flex gap-10 px-[10%] py-[5%]">
      <div className="space-y-10">
        <div className="space-y-3">
          <h3 className="text-[#F43F5E] font-bold text-2xl">STEP 1</h3>
          <ul data-aos="fade-up-left" className="text-xl font-medium">
            <li>Get a Vape Monkey NFT</li>
          </ul>
        </div>
        <div className="space-y-3">
          <h3 className="text-[#F43F5E] font-bold text-2xl">STEP 2</h3>
          <ul data-aos="fade-up-left" className="text-xl font-medium">
            <li>Choose a username</li>
            <li>Select your favourite Vape Monkey NFT</li>
            <li>Place all your links you want to share!</li>
          </ul>
        </div>
        <div className="space-y-3">
          <h3 className="text-[#F43F5E] font-bold text-2xl">STEP 3</h3>
          <ul data-aos="fade-up-left" className="text-xl font-medium">
            <li>Share your one link everywhere 🚀</li>
          </ul>
        </div>
        <p data-aos="fade-up-left" className="text-[#9CA3AF] text-xl">
          Perfect for social media bio or any other <br /> platform you connect
          with people.
        </p>
        <button className="py-[10px] pr-[30px] pl-[20px] border border-[#F43F5E]  text-2xl clip text-[#F43F5E]">
          MINT NOW
        </button>
      </div>
    </div>
  )
}

export default Screen3
